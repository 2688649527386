<header class="user-header__container header--main flex align-center">
  <div class="user-header flex row align-center">
    <div class="user-header-section__container flex row align-center">
      <div class="user-header__left-section flex row align-center justify-center height-100">
        <a class="user-header__logo-container" (click)="navigateLogo()">
          <img
            [ngClass]="{ 'logged-in': currentUserShort }"
            class="user-header__logo"
            [src]="logoToDisplay"
            alt="Jammcard logo"
          />
          <img
            *ngIf="currentUserShort"
            class="user-header__logo-small flex align-center"
            src="{{ logoBlackSmallSimple }}"
            alt="Jammcard logo"
          />
        </a>
      </div>
      <div class="user-header__middle-section flex row align-center" *ngIf="currentUserShort">
        <a
          class="user-header__link flex align-center justify-center"
          routerLink="/search"
          routerLinkActive="user-header__link--active"
          [title]="'HEADER.SEARCH_USERS' | translate"
        >
          <div class="user-header__link-icon">
            <img src="assets/images/icons/search-header.svg" />
          </div>
        </a>
        <a
          class="user-header__link flex align-center justify-center"
          [ngClass]="{ 'add-left-margin': !!(bookingsNotificationsNumber$ | async) }"
          routerLink="/bookings"
          routerLinkActive="user-header__link--active"
          [title]="'HEADER.MY_BOOKINGS' | translate"
        >
          <div class="user-header__link-icon">
            <img src="assets/images/icons/bookings.svg" />
          </div>
          <app-notification-badge
            *ngIf="!!(bookingsNotificationsNumber$ | async)"
            class="flex justify-center align-center"
            [notificationNumber]="bookingsNotificationsNumber$ | async"
          >
          </app-notification-badge>
        </a>
        <a
          class="user-header__link flex align-center justify-center"
          [ngClass]="{ 'add-left-margin': !!(messengerNotificationsNumber$ | async) }"
          routerLink="/messenger"
          routerLinkActive="user-header__link--active"
          [title]="'HEADER.MESSAGES' | translate"
        >
          <div class="user-header__link-icon">
            <img src="assets/images/icons/messenger.svg" />
          </div>
          <app-notification-badge
            *ngIf="!!(messengerNotificationsNumber$ | async)"
            class="flex justify-center align-center"
            [notificationNumber]="messengerNotificationsNumber$ | async"
          >
          </app-notification-badge>
        </a>
        <a
          class="user-header__link flex align-center justify-center"
          routerLink="/jobs"
          routerLinkActive="user-header__link--active"
          *ngIf="currentUserShort.jammboardEnabled"
          [title]="'HEADER.GIGS' | translate"
        >
          <div class="user-header__link-icon jammboard-icon">
            <img src="assets/images/icons/jammboard.svg" />
          </div>
        </a>
      </div>
    </div>
    <div class="flex row align-center justify-center">
      <div
        *ngIf="currentUserShort"
        class="user-header__pages-menu-dropdown--wrapper"
        ngbDropdown
        #pagesMenuDropdown
        display="static"
      >
        <button class="user-header__pages-menu-toggle reset-button-styles" ngbDropdownToggle id="pagesMenuDropdown">
          <img src="assets/images/icons/plus.svg" />
          <div
            *ngIf="!!(bookingsNotificationsNumber$ | async) || !!(messengerNotificationsNumber$ | async)"
            class="indicator"
          ></div>
        </button>
        <div class="user-header__pages-menu-dropdown" ngbDropdownMenu aria-labelledby="pagesMenuDropdown">
          <a
            class="user-header__pages-menu-dropdown--link add-right-margin dropdown__link flex align-center"
            routerLink="/search"
          >
            <div class="user-header__link-icon">
              <img src="assets/images/icons/search-header.svg" width="24px" />
            </div>
            {{ 'HEADER.SEARCH' | translate }}
          </a>
          <a
            *ngIf="currentUserShort.jammboardEnabled"
            class="user-header__pages-menu-dropdown--link add-right-margin dropdown__link flex align-center"
            routerLink="/jobs"
          >
            <div class="user-header__link-icon jammboard-icon">
              <img src="assets/images/icons/jammboard.svg" />
            </div>
            {{ 'HEADER.GIGS' | translate }}
          </a>
          <a
            class="user-header__pages-menu-dropdown--link dropdown__link flex align-center"
            [ngClass]="{ 'add-right-margin': !(messengerNotificationsNumber$ | async) }"
            routerLink="/messenger"
          >
            <div class="user-header__link-icon">
              <img src="assets/images/icons/messenger.svg" />
            </div>
            <app-notification-badge
              *ngIf="!!(messengerNotificationsNumber$ | async)"
              class="flex justify-center align-center"
              [notificationNumber]="messengerNotificationsNumber$ | async"
            >
            </app-notification-badge>
            {{ 'HEADER.MESSAGES' | translate }}
          </a>
          <a
            class="user-header__pages-menu-dropdown--link dropdown__link flex align-center"
            [ngClass]="{ 'add-right-margin': !(bookingsNotificationsNumber$ | async) }"
            routerLink="/bookings"
          >
            <div class="user-header__link-icon">
              <img src="assets/images/icons/bookings.svg" />
            </div>
            <app-notification-badge
              *ngIf="!!(bookingsNotificationsNumber$ | async)"
              class="flex justify-center align-center"
              [notificationNumber]="bookingsNotificationsNumber$ | async"
            >
            </app-notification-badge>
            {{ 'HEADER.MY_BOOKINGS' | translate }}
          </a>
        </div>
      </div>
      <app-more-dropdown-menu></app-more-dropdown-menu>
      <div *ngIf="currentUserShort" class="account-info flex row align-center justify-center">
        <div
          class="account-info__settings flex row align-center justify-center"
          ngbDropdown
          autoClose="true"
          [placement]="['bottom-right']"
        >
          <div class="account-info__wrapper flex row align-center justify-center" id="dropdownBasic1" ngbDropdownToggle>
            <div class="account-info__image" style="background-image: url({{ loggedUserImage }});"></div>
            <span class="account-info__name">{{ currentUserShort?.name }}</span>
          </div>
          <div class="account-info__dropdown" ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <div class="dropdown__link-wrapper">
              <a
                class="dropdown__my-profile dropdown__link"
                [routerLink]="'/' + currentUserShort?.username"
                ngbDropdownItem
              >
                <div class="dropdown__my-profile--image" style="background-image: url({{ loggedUserImage }});"></div>
                <div class="dropdown__my-profile--info">
                  <div class="dropdown__my-profile--name">{{ currentUserShort?.name }}</div>
                  <div class="dropdown__my-profile--text">{{ 'HEADER.SEE_MY_PROFILE' | translate }}</div>
                </div>
              </a>
            </div>
            <hr class="account-info__divider" />
            <div class="dropdown__link-wrapper" *ngIf="loggedUserIsBuyer">
              <a
                class="dropdown__link add-arrow become-member__link flex align-center"
                href="{{ url.BECOME_MEMBER }}"
                target="_blank"
                rel="noreferrer"
                ngbDropdownItem
              >
                {{ 'HEADER.BECOME_A_MEMBER' | translate }}
              </a>
            </div>
            <div class="dropdown__link-wrapper">
              <a
                class="dropdown__link add-arrow settings__link flex align-center"
                routerLink="/settings"
                routerLinkActive="active"
                ngbDropdownItem
              >
                {{ 'HEADER.ACCOUNT' | translate }}
              </a>
            </div>
            <div class="dropdown__link-wrapper">
              <a
                class="dropdown__link add-arrow payment__link flex align-center"
                routerLink="/settings"
                [queryParams]="{ tab: 'payment-and-payout' }"
                routerLinkActive="active"
                ngbDropdownItem
              >
                {{ 'HEADER.PAYMENT_AND_PAYOUT' | translate }}
              </a>
            </div>
            <div *ngIf="currentUserShort.jammboardEnabled" class="dropdown__link-wrapper">
              <a
                class="dropdown__link add-arrow my-opportunities__link flex align-center"
                routerLink="/my-opportunities"
                routerLinkActive="active"
                ngbDropdownItem
              >
                {{ 'HEADER.MY_GIGS' | translate }}
              </a>
            </div>
            <div class="dropdown__link-wrapper">
              <a
                class="dropdown__link add-arrow contact-support__link flex align-center"
                href="mailto:{{ supportEmail }}"
                title="{{ 'HEADER.EMAIL_TO' | translate }} {{ supportEmail }}"
                target="_blank"
                rel="noopener noreferrer"
                ngbDropdownItem
              >
                {{ 'HEADER.CONTACT_SUPPORT' | translate }}
              </a>
            </div>
            <hr class="account-info__divider" />
            <div
              class="account-info__logout-section flex column justify-center"
              [ngClass]="{ 'not-buyer': !loggedUserIsBuyer }"
            >
              <button id="logoutButton" (click)="logout()" class="logout-button uppercase" ngbDropdownItem>
                {{ 'HEADER.LOG_OUT' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-login-button *ngIf="!currentUserShort"></app-login-button>
    </div>
  </div>
</header>
